import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '@app/services/auth.service';
import { APP_CONFIGURATION } from '@app/config/app-configuration';
import { LoaderService } from '@app/services/loader.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private loaderSerivce: LoaderService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401) {
          let url = location.href;
          url = decodeURIComponent(url);

          if (url && url.includes('authenticated=false')) {
            this.router.navigate(['/error/unauthorized']);
          } else {
            const e = (err.error && err.error.error) || null;
            switch (e) {
              case APP_CONFIGURATION.HTTP_ERROR_RESPONSE_CODES.UNAUTHENTICATED:
                this.authService.logout();
                this.authService.login();
                break;
              case APP_CONFIGURATION.HTTP_ERROR_RESPONSE_CODES
                .UNAUTHORIZED_USER:
                this.router.navigate(['/error/unauthorized']);
                break;
              case APP_CONFIGURATION.HTTP_ERROR_RESPONSE_CODES
                .UNAUTHORIZED_STORE_CONFIG:
                this.router.navigate(['/error/unauthorized']);
                break;
              case APP_CONFIGURATION.HTTP_ERROR_RESPONSE_CODES
                .UNAUTHORIZED_NETWORK:
                this.router.navigate(['/error/unauthorized']);
                break;
              default:
                this.router.navigate(['/error/unauthorized']);
                break;
            }
          }
        } else if (err.status === 404) {
          this.router.navigate(['/error/404']);
        }

        this.loaderSerivce.hideLoader();

        const error = err.message || err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
