import { HttpService } from './http.service';
import { WebPushSubscription } from './../../data/models/web-push-subscription';
import { Injectable } from '@angular/core';
import { environment } from '@env';

const BASE_API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private httpService: HttpService) {}

  /**
   * Calls the API endpoint which registers the user to receive web push notification
   *
   * @param subscription ~web push notification subscription
   */
  registerPushSubscriber(subscription: WebPushSubscription) {
    return this.httpService.post(`/api/v2/notification/register/web`, subscription);
  }

  sendTestPushNotification() {
    const webNotificationToSend = {
      payload: {
        title: 'DSW Test Notification',
        body: 'This is a test notification',
        icon: 'assets/icons/logo.png',
        vibrate: [
          100,
          50,
          100
        ]
      }
    };
    return this.httpService.post(`/api/v2/notification/web`, webNotificationToSend);
  }
}
