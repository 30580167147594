import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AuthService, HttpService } from './services';
import { ConfigInterceptor, ErrorInterceptor } from './interceptors';
import { WINDOW_PROVIDERS } from './services/window.service';

@NgModule({ imports: [CommonModule,
        BrowserModule,
        BrowserAnimationsModule], providers: [
        HttpService,
        AuthService,
        { provide: HTTP_INTERCEPTORS, useClass: ConfigInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        WINDOW_PROVIDERS,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class CoreModule {}
