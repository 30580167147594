import { Component, OnInit, Inject } from '@angular/core';
import { LoaderService } from '@app/services/loader.service';
import { debounceTime, filter } from 'rxjs/operators';
import { APP_CONFIGURATION } from './core';
import { SwPush } from '@angular/service-worker';
import { NotificationService } from '@app/services/notification.service';
import { WebPushSubscription } from './data/models/web-push-subscription';
import { RouteService } from '@app/services/route.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppLinkService } from '@app/services';
import { WINDOW } from '@app/services/window.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public spinning: boolean;
  private sub: WebPushSubscription;

  constructor(
    @Inject(WINDOW) private window: Window,
    private loaderService: LoaderService,
    private routeService: RouteService,
    private route: ActivatedRoute,
    private appLinkService: AppLinkService,
    private swPush: SwPush,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.checkBrowserSupport();
    this.createSpinner();
    this.subscribeToNotifications();
    this.routeService.onNavigationStart().subscribe();

    this.route.queryParamMap
      .pipe(filter(paramMap => paramMap.has('applink')))
      .subscribe(paramMap => {
        this.loaderService.showLoader();
        this.appLinkService.handleLink(paramMap.get('applink'));
      });
    this.resizeBrowser();
  }

  private createSpinner() {
    this.loaderService.$loading
      .pipe(debounceTime(APP_CONFIGURATION.LOADING_DELAY_MS))
      .subscribe(value => {
        this.spinning = value;
      });
  }

  private subscribeToNotifications() {
    this.swPush
      .requestSubscription({
        serverPublicKey: APP_CONFIGURATION.PUSH_NOTIFICATION_VAPID_PUBLIC_KEY
      })
      .then(sub => {
        this.sub = new WebPushSubscription(sub);

        this.notificationService.registerPushSubscriber(this.sub).subscribe(
          () => {
            console.log('Sent push subscription object to server.');
          },
          err =>
            console.log(
              'Could not send subscription object to server, reason: ',
              err
            )
        );
      })
      .catch((err: any) =>
        console.error('Could not subscribe to notifications', err)
      );
  }

  private setAppHeight() {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  }

  private resizeBrowser() {
    window.addEventListener('resize', this.setAppHeight);
    this.setAppHeight();
  }

  private checkBrowserSupport() {
    // App does not support Internet Explorer. If detected, redirect to unsupported view
    const ua = this.window.navigator.userAgent;
    if (ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1) {
      this.router.navigate(['/error/unsupported']);
    }
  }
}
