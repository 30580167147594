import { Component, OnInit, OnDestroy } from '@angular/core';
import { SvgIcon } from '@models';
import { AlertService } from '@app/services';
import { takeUntil, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { OverlayService } from '@app/services/overlay.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<boolean>();

  public iconConfig = new SvgIcon('alerts');
  public alertCount: number;

  constructor(
    private overlayService: OverlayService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.initializeComponent();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  private destroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  private initializeComponent() {
    this.alertService
      .alerts()
      .pipe(
        map(alerts => {
          return (alerts && alerts.length) || 0;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(alertCount => {
        this.alertCount = alertCount;
      });
  }

  public toggleShowAlerts() {
    this.overlayService.toggleShowAlerts();
  }
}
