import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { SvgIcon, Modal, UserViewModel, ProxyRole } from '@models';
import { AuthService, ModalService } from '@app/services';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<boolean>();

  public iconConfig = new SvgIcon('profile');
  public userDisplayName: string;
  public modalConfig: Modal;
  public user: UserViewModel;

  constructor(
    private authService: AuthService,
    private modalService: ModalService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authService
      .getCurrentUser()
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe(user => {
        this.user = user;
        this.initProfileComponent(user);
      });
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  private destroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  // initialize the profile control after getting the current user
  private initProfileComponent(user: UserViewModel) {
    this.setUserDisplayName(user);
    this.configureModal(user);
  }

  // configure user display name
  private setUserDisplayName(user: UserViewModel): void {
    if (user) {
      if (user.proxyUser && user.proxyUser.role && user.proxyUser.identity) {
        if (user.proxyUser.role === ProxyRole.Store) {
          this.userDisplayName = `${user.proxyUser.role} Manager - ${user.proxyUser.store}`;
        } else if (user.proxyUser.role === ProxyRole.DistictManager) {
          this.userDisplayName = `${user.proxyUser.role} - ${user.proxyUser.identity} District`;
        } else if (user.proxyUser.role === ProxyRole.RegionalDirector) {
          this.userDisplayName = `${user.proxyUser.role} - ${user.proxyUser.identity}`;
        }
      } else if (user && user.fieldLeader) {
        this.userDisplayName = user.fullName;
      } else {
        if (user.store) {
          this.userDisplayName = `Store ${user.store}`;
        } else {
          this.userDisplayName = user.fullName;
        }
      }
    }
  }

  // configure modal
  private configureModal(user: UserViewModel): void {
    if (user) {
      const id = 'profile-modal';
      let body = 'You are logged in as ';
      let title = 'Switch Roles';
      if (user.proxyUser && user.proxyUser.role) {
        body += `${user.proxyUser.roleShort}`;
        if (user.proxyUser.role === ProxyRole.DistictManager) {
          body += ` (${user.proxyUser.identity} District).<br><br>You may switch to another<br>Store/District/Region or Log Out`;
        } else if (user.proxyUser.role === ProxyRole.RegionalDirector) {
          body += ` (${user.proxyUser.identity}).<br><br>You may switch to another<br>Store/District/Region or Log Out`;
        } else if (user.proxyUser.role === ProxyRole.Store) {
          body += ` (${user.proxyUser.store}).<br><br>You may switch to another<br>Store/District/Region or Log Out`;
        }
      } else {
        title = 'Log Out';
        body += `${user.fullName} (${user.id}).\n\nWould you like to log out?`;
      }
      this.modalConfig = {
        title,
        body,
        id
      };
    }
  }

  // open modal
  public openModal(id: string) {
    this.modalService.open(id);
  }

  // close modal
  public closeModal(id: string) {
    this.modalService.close(id);
  }

  // log user out by clearing session data and route user to logout page
  public logOut() {
    this.authService.logout();
    this.router.navigateByUrl('/logout');
  }

  // navigate to proxy screen
  public switchRole() {
    this.router.navigateByUrl('/proxy');
  }
}
