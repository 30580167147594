import { AuthService } from '@app/services/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { APP_CONFIGURATION } from '@app/config/app-configuration';
import { LocalStorage } from '@app/utilities';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const currentUser = this.authService.currentUserValue;
    let returnUrl: string;

    // current user is not logged on, log in user
    if (!currentUser) {
      const storeId = state.root.queryParamMap.get(
        APP_CONFIGURATION.HTTP_PARAMS.STORENUMBER
      );

      if (storeId) {
        LocalStorage.set(
          APP_CONFIGURATION.LOCAL_STORAGE_KEYS.STORENUMBER,
          storeId
        );
      }

      const storeCache = state.root.queryParamMap.get(
        APP_CONFIGURATION.LOCAL_STORAGE_KEYS.STORE_CACHE
      );

      if (storeCache) {
        LocalStorage.set(
          APP_CONFIGURATION.LOCAL_STORAGE_KEYS.STORE_CACHE,
          storeCache
        );
      }

      const systemType = state.root.queryParamMap.get(
        APP_CONFIGURATION.LOCAL_STORAGE_KEYS.SYSTEM_TYPE
      );

      if (systemType) {
        LocalStorage.set(
          APP_CONFIGURATION.LOCAL_STORAGE_KEYS.SYSTEM_TYPE,
          systemType
        );
      }

      const authenticated = state.root.queryParamMap.get(
        APP_CONFIGURATION.HTTP_PARAMS.AUTHENTICATED
      );

      if (authenticated === 'true') {
        returnUrl = LocalStorage.get(
          APP_CONFIGURATION.LOCAL_STORAGE_KEYS.RETURN_URL
        );
        LocalStorage.remove(APP_CONFIGURATION.LOCAL_STORAGE_KEYS.RETURN_URL);
      } else {
        const queryParams = [
          APP_CONFIGURATION.HTTP_PARAMS.STORENUMBER,
          APP_CONFIGURATION.LOCAL_STORAGE_KEYS.STORE_CACHE
        ];

        const cleanedUrl = this.removeQueryParam(state.url, queryParams);

        LocalStorage.set(
          APP_CONFIGURATION.LOCAL_STORAGE_KEYS.RETURN_URL,
          cleanedUrl
        );

        returnUrl = cleanedUrl;
      }

      this.router.navigate(['/login'], {
        queryParams: {
          returnUrl
        }
      });

      return false;
    }

    // non-proxy user or proxy user is configured, allow
    if (!currentUser.isProxyUser || currentUser.proxyUser) {
      return true;
    } else {
      // proxy user object isn't configured, navigate to proxy screen
      this.router.navigate(['/proxy'], {
        queryParams: {
          returnUrl: this.removeQueryParam(state.url, [
            APP_CONFIGURATION.HTTP_PARAMS.STORENUMBER
          ])
        }
      });
    }

    return false;
  }

  private removeQueryParam(url: string, queryParams: string[]): string {
    const origin = new URL(environment.rootUrl).origin;
    const urlObj = new URL(`${origin}${url}`);

    queryParams.forEach(queryParam => {
      urlObj.searchParams.delete(queryParam);
    });

    return urlObj.href.replace(origin, '');
  }
}
