import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationLinkViewModel } from '@models';
import { RouteService } from '@app/services/route.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<boolean>();
  public navigationLinks: NavigationLinkViewModel[];

  constructor(private routeService: RouteService) {}

  ngOnInit() {
    this.initNavigationLinks();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  private destroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  private initNavigationLinks() {
    this.routeService
      .onNavigationLinks()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(links => {
        this.navigationLinks = links;
      });
  }
}
