import { Injectable } from '@angular/core';
import { Router, Event, NavigationStart } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { NavigationLinkViewModel } from '@models';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  private navigationLinksSubject: BehaviorSubject<NavigationLinkViewModel[]>;

  constructor(private router: Router) {
    this.navigationLinksSubject = new BehaviorSubject<
      NavigationLinkViewModel[]
    >([
      {
        link: {
          text: 'Home',
          route: '/',
          root: '/',
          isRouterLinkExactMatch: true
        },
        icon: {
          icon: 'tab-home',
          cssClass: 'icon-tab-home-dims'
        },
        iconActive: {
          icon: 'tab-home-selected',
          cssClass: 'icon-tab-home-selected-dims'
        }
      },
      {
        link: {
          text: 'Daily Tasks',
          route: '/daily-tasks',
          root: '/daily-tasks',
          isRouterLinkExactMatch: false
        },
        icon: {
          icon: 'tab-daily-tasks',
          cssClass: 'icon-tab-daily-tasks-dims'
        },
        iconActive: {
          icon: 'tab-daily-tasks-selected',
          cssClass: 'icon-tab-daily-tasks-selected-dims'
        }
      },
      {
        link: {
          text: 'Calendar',
          route: '/calendar',
          root: '/calendar',
          isRouterLinkExactMatch: false
        },
        icon: {
          icon: 'tab-calendar',
          cssClass: 'icon-tab-calendar-dims'
        },
        iconActive: {
          icon: 'tab-calendar-selected',
          cssClass: 'icon-tab-calendar-selected-dims'
        }
      },
      {
        link: {
          text: 'Need to Know',
          route: '/need-to-know',
          root: '/need-to-know',
          isRouterLinkExactMatch: false
        },
        icon: {
          icon: 'tab-need-to-know',
          cssClass: 'icon-tab-need-to-know-dims'
        },
        iconActive: {
          icon: 'tab-need-to-know-selected',
          cssClass: 'icon-tab-need-to-know-selected-dims'
        }
      },
      {
        link: {
          text: 'Reference',
          route: '/reference',
          root: '/reference',
          isRouterLinkExactMatch: false
        },
        icon: {
          icon: 'tab-documents',
          cssClass: 'icon-tab-documents-dims'
        },
        iconActive: {
          icon: 'tab-documents-selected',
          cssClass: 'icon-tab-documents-selected-dims'
        }
      }
    ]);
  }

  public onNavigationLinks(): Observable<NavigationLinkViewModel[]> {
    return this.navigationLinksSubject.asObservable();
  }

  public onNavigationStart(): Observable<Event> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
      tap((event: NavigationStart) => {
        const navLinks = this.navigationLinksSubject.value;

        const origin = new URL(environment.rootUrl).origin;
        const pathFromOrigin = new URL(origin).pathname;

        const destinationUrl = new URL(`${origin}${event.url}`);
        const currentUrl = new URL(`${origin}${this.router.url}`);

        const destinationRoot = this.getRoot(destinationUrl.pathname, pathFromOrigin);
        const currentRoot = this.getRoot(currentUrl.pathname, pathFromOrigin);

        navLinks.forEach(navLink => {
          // set route on tab of navigation start - condition always evaluated
          if (navLink.link.root === currentRoot) {
            // if leaving tab
            if (currentRoot !== destinationRoot) {
              // set old tab to last route
              navLink.link.route = currentUrl.pathname;
              navLink.link.query = this.getQueryParams(currentUrl.searchParams);
              navLink.link.hash = this.getHash(currentUrl.hash);
              // if staying on tab
            } else {
              // set current tab to root
              navLink.link.route = currentRoot;
              navLink.link.query = null;
              navLink.link.hash = null;
            }
            // set route on tab of destination - only enters code block if leaving tab
          } else if (navLink.link.root === destinationRoot) {
            navLink.link.route = destinationRoot;
            navLink.link.query = null;
            navLink.link.hash = null;
          }
        });

        this.navigationLinksSubject.next(navLinks);
      })
    );
  }

  private getRoot(url: string, pathFromOrigin: string): string {
    let root = '/';
    const relativeUrl = url.substring(pathFromOrigin.length - 1, url.length);
    if (relativeUrl) {
      const path = relativeUrl.split('/');
      root += (path.length > 1 && path[1]) || '';
    }
    return root;
  }

  private getQueryParams(queryParams: URLSearchParams): any {
    let queryObj = {};
    queryParams.forEach((value: string, key: string) => {
      if (key !== 'applink') {
        queryObj = { ...queryObj, [key]: value };
      }
    });
    return queryObj;
  }

  private getHash(hash: string): string {
    return !!hash ? hash.substring(1, hash.length) : null; // remove '#'
  }
}
