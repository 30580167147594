import { APP_CONFIGURATION } from './../config/app-configuration';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AuthService } from '@app/services';
import { environment } from '@env';
import { ProxyRole } from '@models';
import { LocalStorage } from '@app/utilities';

const BASE_API_URL = environment.apiUrl;

@Injectable()
export class ConfigInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.startsWith(BASE_API_URL)) {
      const httpParams = this.performExtraConfiguration(request.params);
      const requestWithAdditonalConfig = request.clone({ params: httpParams });
      return next.handle(requestWithAdditonalConfig);
    }

    return next.handle(request);
  }

  private performExtraConfiguration(params: HttpParams): HttpParams {
    const user = this.authService.currentUserValue;

    // Add auth key
    params = params.append(
      APP_CONFIGURATION.HTTP_PARAMS.KEY,
      environment.apiKey
    );

    if (user && user.proxyUser && user.proxyUser.role) {
      if (user.proxyUser.role === ProxyRole.Store) {
        params = params.append(
          APP_CONFIGURATION.HTTP_PARAMS.STORE,
          user.proxyUser.store
        );
      } else if (user.proxyUser.role === ProxyRole.RegionalDirector) {
        params = params.append(
          APP_CONFIGURATION.HTTP_PARAMS.REGION,
          user.proxyUser.region
        );
      } else if (user.proxyUser.role === ProxyRole.DistictManager) {
        params = params.append(
          APP_CONFIGURATION.HTTP_PARAMS.DISTRICT,
          user.proxyUser.district
        );
      }
      params = params.append(APP_CONFIGURATION.HTTP_PARAMS.PROXY_USER, 'true');
      return params;
    } else {
      const storeId = LocalStorage.get(
        APP_CONFIGURATION.LOCAL_STORAGE_KEYS.STORENUMBER
      );

      if (storeId) {
        params = params.append(APP_CONFIGURATION.HTTP_PARAMS.STORE, storeId);
      }

      return params;
    }
  }
}
