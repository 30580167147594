<a class="profile" (click)="openModal('profile-modal')">
  <app-svg-icon class="profile__icon" [config]="iconConfig"></app-svg-icon>
  <span class="profile__name">{{userDisplayName}}</span>
</a>

<app-modal *ngIf='user' [config]="modalConfig">
  <app-modal-action *ngIf='user.proxyUser' [title]="'Switch Role'" (clickEvent)="switchRole()"></app-modal-action>
  <app-modal-action [title]="'Log Out'" (clickEvent)="logOut()"></app-modal-action>
  <app-modal-action [title]="'Cancel'" (clickEvent)="closeModal('profile-modal')"></app-modal-action>
</app-modal>
