<footer class="footer">
  <div class="footer__nav" *ngIf="navigationLinks">
    <a
      *ngFor="let navLink of navigationLinks"
      class="footer__nav__link"
      [routerLink]="navLink.link.route"
      [queryParams]="navLink.link.query"
      [fragment]="navLink.link.hash"
      [routerLinkActiveOptions]="{ exact: navLink.link.isRouterLinkExactMatch }"
      routerLinkActive="footer__nav__link--active"
    >
      <div class="footer__nav__link__icon">
        <app-svg-icon [config]="navLink.icon"></app-svg-icon>
      </div>
      <div class="footer__nav__link__icon--active">
        <app-svg-icon [config]="navLink.iconActive"></app-svg-icon>
      </div>
      <div class="footer__nav__link__text">{{ navLink.link.text }}</div>
    </a>
  </div>
</footer>
