import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreadcrumbService } from '@app/services/breadcrumb.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnDestroy {
  private unsubscribe$ = new Subject<boolean>();

  public title: string;

  constructor(private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService
      .getTitle()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(title => {
        this.title = title;
      });

    this.breadcrumbService
      .setBreadcrumbTitleFromRoute()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  private destroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
