import { SvgIcon } from '@models';
import { Component, OnInit } from '@angular/core';
import { OverlayService } from '@app/services/overlay.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  public iconConfig = new SvgIcon('search');

  constructor(private overlayService: OverlayService) {}

  ngOnInit() {}

  public toggleShowSearch() {
    this.overlayService.toggleShowSearch();
  }
}
