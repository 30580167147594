import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-svg-document',
  templateUrl: './svg-document.component.svg',
  styleUrls: ['./svg-document.component.scss']
})
export class SvgDocumentComponent implements OnInit, OnDestroy {
  private originalSvg: string;
  private unsubscribe$ = new Subject<boolean>();

  constructor(private router: Router) {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      this.router.events
        .pipe(
          takeUntil(this.unsubscribe$),
          filter(event => event instanceof NavigationEnd)
        )
        .subscribe(() => {
          if (this.originalSvg) {
            this.updateSvgDocument();
          } else {
            this.setOriginalSvg();
            this.updateSvgDocument();
          }
        });
    }
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.destroy();
  }

  private updateSvgDocument(): void {
    if (this.originalSvg) {
      const replacedSvg = this.originalSvg.replace(
        /url\(/g,
        'url(' + window.location.href
      );

      const inlineSvg = document.getElementById('inlineSvgDocument');
      if (inlineSvg) {
        inlineSvg.outerHTML = replacedSvg;
      }
    }
  }

  private setOriginalSvg(): void {
    const inlineSvg = document.getElementById('inlineSvgDocument');
    if (inlineSvg) {
      this.originalSvg = inlineSvg.outerHTML;
    }
  }

  private destroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
