import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ProxyRole } from '@models';
import { AuthService } from '@app/services';

@Injectable({
  providedIn: 'root'
})
export class ProxyGuard  {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const currentUser = this.authService.currentUserValue;

    if (currentUser.isProxyUser) {
      return true;
    }

    this.router.navigate(['/error/unauthorized']);
    return false;
  }
}
