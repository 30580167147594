import { AuthGuard } from '@app/guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { RoleGuard } from '@app/guards/role.guard';
import { ProxyGuard } from '@app/guards/proxy.guard';

const routes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: ''
    },
    component: ContentLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        data: {
          breadcrumb: 'Home'
        },
        loadChildren: () =>
          import('@modules/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'calendar',
        data: {
          breadcrumb: 'Calendar'
        },
        loadChildren: () =>
          import('@modules/calendar/calendar.module').then(
            m => m.CalendarModule
          )
      },
      {
        path: 'daily-tasks',
        data: {
          breadcrumb: 'Daily Tasks'
        },
        loadChildren: () =>
          import('@modules/daily-tasks/daily-tasks.module').then(
            m => m.DailyTasksModule
          )
      },
      {
        path: 'need-to-know',
        data: {
          breadcrumb: 'Need to Know'
        },
        loadChildren: () =>
          import('@modules/need-to-know/need-to-know.module').then(
            m => m.NeedToKnowModule
          )
      },
      {
        path: 'reference',
        data: {
          breadcrumb: 'Reference'
        },
        loadChildren: () =>
          import('@modules/reference/reference.module').then(
            m => m.ReferenceModule
          )
      }
    ]
  },
  {
    path: 'login',
    loadChildren: () =>
      import('@modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('@modules/logout/logout.module').then(m => m.LogoutModule)
  },
  {
    path: 'error/unauthorized',
    loadChildren: () =>
      import('@modules/error/unauthorized/unauthorized.module').then(
        m => m.UnauthorizedModule
      )
  },
  {
    path: 'error/unsupported',
    loadChildren: () =>
      import('@modules/error/unsupported/unsupported.module').then(
        m => m.UnsupportedModule
      )
  },
  {
    path: 'error/404',
    loadChildren: () =>
      import('@modules/error/404/file-not-found.module').then(
        m => m.FileNotFoundModule
      )
  },
  {
    path: 'proxy',
    canActivate: [ProxyGuard],
    loadChildren: () =>
      import('@modules/proxy/proxy.module').then(m => m.ProxyModule)
  },
  { path: '**', redirectTo: 'error/404', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
