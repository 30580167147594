<header class="header">
  <div class="header--max-width">
    <div class="header__profile">
      <app-profile></app-profile>
    </div>
    <div class="header__page-title">
      <app-breadcrumb></app-breadcrumb>
    </div>
    <div class="header__actions">
      <div class="header__actions__search">
        <app-search></app-search>
      </div>
      <div class="header__actions__alert">
        <app-alerts></app-alerts>
      </div>
    </div>
  </div>
</header>
