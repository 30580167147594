<a (click)="toggleShowAlerts()">
  <span
  [matBadge]="alertCount"
  [matBadgeHidden]="!alertCount"
  matBadgeColor="warn"
  matBadgePosition="above before"
  matBadgeSize="small"></span>
  <app-svg-icon
    [config]="iconConfig"
  ></app-svg-icon>
</a>
